
.drawercar {
  width: 240px; 
  transition: width 0.4s;
  height: 100%;
  flex-shrink: 0;
  background-color: #AA1539;
  position: absolute;
  box-shadow:0px 0px 4px 0px rgb(150 150 150 / 88%);
  /* overflow: ; */
}
.drawercarc{
  width: 0px; 
  transition: width 0.4s;
  height: 100%;
  flex-shrink: 0;
  background-color: #AA1539;
  position: absolute;
  box-shadow:0px 0px 4px 0px rgb(150 150 150 / 88%);
  overflow: auto;

}
.drawercard {
    width: 240px; 
    transition: width 0.4s;
    height: 100%;
    padding-bottom: 100px;
    flex-shrink: 0;
    background-color: white;
    position: absolute;
    box-shadow:0px 0px 4px 0px rgb(150 150 150 / 88%);
    overflow: auto;
  }
  .drawercardc{
    width: 0px; 
    transition: width 0.4s;
    height: -webkit-fill-available;
    flex-shrink: 0;
    background-color: white;
    position: absolute;
    box-shadow:0px 0px 4px 0px rgb(150 150 150 / 88%);
    overflow: auto;
  
  }
  .drawercard::-webkit-scrollbar {
    width: 1px; 
  }
  
  .drawercard::-webkit-scrollbar-thumb {
    /* background-color: green;  */
    border-radius: 5px; 
  }
  
  .drawercard {
    scrollbar-width: 1px; 
  }
  
  .drawercard::-moz-scrollbar-thumb {
    /* background-color: green;  */
    border-radius: 5px;
  }

  /* CSS classes for enabled and disabled rows */
.enabled-row {
  background-color: white; /* Background color for enabled rows */
}

.disabled-row {
  background-color: lightgray; /* Background color for disabled rows */
}
