.success-message-container {
    position: fixed;
    top: -60px; 
    left: 30%;
    right: 30%;
    background-color: #4caf50;
    color: white;
    padding: 10px;
    text-align: center;
    z-index: 999;
    transition: top 0.5s ease-in-out;
    border-radius:20px

  }
  

  .show-success-message {
    top: 20px; 
  }
  