.main{
    padding-top: 30px;
    /* height: 100%;
    overflow: auto; */
   
   
  }
  .drawer-container {
      display: flex;
    }
    
    .content-wrapper {
      padding-top: 90px;
      flex: 1;
      margin-left: 270px;
      margin-right: 30px;
      transition: margin-left 0.4s;
      padding-top: 63px;
      /* overflow-y: auto; */
      height: 90%;
      
      
    }
    .cdf{
      padding-top: 90px;
      flex: 1;
      margin-left: 50px;
      margin-right: 30px;
      transition: margin-left 0.4s;
     
      
    }
    .headertoggle{
      flex: 1;
      margin-left: 240px;
      transition: margin-left 0.4s;
  
    }
    .headert{
       flex: 1;
      margin-left: 0px;
      transition: margin-left 0.4s;
  
    }