.modal-header{
    /* display: flex; */
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-modal-header-padding);
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
    border-top-left-radius: var(--bs-modal-inner-border-radius);
    border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-title {
    width: 100%;
    margin-bottom: 0;
    line-height: var(--bs-modal-title-line-height);
}