.Bkgcolor{
    flex: 1;
    background: linear-gradient(
        
        to bottom right,
        #AA1539 0%,
        #000F3F  50%,                                                                 
        #570000 50%,
        #1484CD 100%
    );
  
    
    color: black;
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .account:hover{
    
        color:"red"

  }
  .account:hover {
	color: blue;
}
