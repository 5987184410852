.spinner-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999; /* Ensure the spinner is on top of other content */
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; 
    border: 1px solid white;
    /* background-color: aliceblue; */
    /* padding: 10px; */
  }
  .myButton {
    /* Set the default color */
    background-color: transparent;
    border: 1px solid lightgray;
    color: black;
    width: 99%;
  }
  
  .myButton:hover {
   
    background-color: grey;
    border: 1px solid lightgray;
    width: 99%;
  }
  .Document-createDateasc{
    padding: 4px;
  }
  .Document-createDateasc :hover{
    padding: 4px;
    background-color: antiquewhite;
  }
  