
.custom-table {
  margin-bottom: 0; 
  padding: 0px 0px;
}
/* .sticky-header {
  position: sticky;
  top: -0.1;
} */


 
   .history-container {
    scrollbar-width: thin; /* For Firefox */
  }
  .history-container::-webkit-scrollbar {
    width: 0.5px; /* Adjust the width as needed */
  }
  
  .history-container::-webkit-scrollbar-track {
    background: #f0f0f0; /* Background color of the scrollbar track */
  }
  
  .history-container::-webkit-scrollbar-thumb {
    background: #ccc; /* Color of the scrollbar thumb */
  }