.history-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  scrollbar-width: thin; /* For Firefox */
}
  
  .history-item {
    background-color: #f0f0f0; /* Gray background color */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .editor-name {
    font-weight: bold;
  }
  
  .date-time {
    margin-top: 10px;
  }
  
  
 
  
  .history-container::-webkit-scrollbar {
    width: 6px; /* Adjust the width as needed */
  }
  
  .history-container::-webkit-scrollbar-track {
    background: #f0f0f0; /* Background color of the scrollbar track */
  }
  
  .history-container::-webkit-scrollbar-thumb {
    background: #ccc; /* Color of the scrollbar thumb */
  }
  
  
  
  