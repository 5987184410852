/* Menu.css */

/* Active main menu item */
.main-menu .active-item {
  background-color: #ff0000; /* Set your desired active main menu background color */
  color: #ffffff; /* Set your desired active main menu text color */
}

/* Active submenu item */
.sub-menu .active-item {
  background-color:  #7f9af3; /* Set your desired active submenu background color */
  color: #000000; /* Set your desired active submenu text color */
  
}


.active-item {
    background-color: #000F3F;
    color: #ffff;
  }
  .active-item:hover {
    background-color: #000F3F;
    color: #ffff;
  }
  .hdh:hover{
    background-color:#000F3F;
    color: #ffff;
  }
  .hdh{
    color: #000000;
  }
  .items-menu {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-items: center;
    padding-left: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .anticon{
    line-height: 0.9;
    text-align: center;
    vertical-align: em;
    margin-right: 15px;
  }
  .anticon1{
    line-height: 0.9;
    text-align: center;
    text-transform: none;
    vertical-align: em;
    margin-right: 15px;

  }

.fonts:hover{
  color: #ffff;
font-size: 18px;
font-style: normal;
font-weight: 400;

}
  .fonts{
/* color: #ffff; */
/* font-family: Open Sans; */
font-size: 18px;
font-style: normal;
font-weight: 400;
/* opacity: 0.5; */
/* line-height: normal; */
  }
  .fonts1{
    /* color: #000000; */
    /* font-family: Open Sans; */
    font-size: 18px;
    /* font-style: normal; */
    font-weight: 400;
    /* opacity: 0.5; */
    /* line-height: normal; */
      }
      .fonts1:focus{
        /* color: #ffff; */
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      
      }
      .items-submenu {
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-items: center;
        padding-left: 45px;
        padding-top: 10px;
        padding-bottom: 10px;
      }