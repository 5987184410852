    .mainheader{
        width:calc(100vw - 200px);
        height: 80px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        z-index: 1001;
    }
    .main1header{
        width: 100%;
        height: 67px;
        /* transition: width 0.4s; */
        /* background-color: #AA1539;
        position: fixed; */
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        z-index: 1001;
    }
  /* styles.css */

.headericon {
    /* Define your default styles */
    color: black; /* Default color */
    /* Add other default styles as needed */
  }
  
  .headericon:hover {
    /* Define your hover styles */
    color: blue; /* Hover color */
    /* Add other hover styles as needed */
  }
  .user{
    color: black;
    background-color: white;
    border:0.5px solid black;
    font-size:20px;
    padding:4px;
    border-radius:50%;
  }
  .user:hover{
    color:white;
    background-color: lightgray;
    border:0.5px solid lightgray;
  }
  